<template>
	<Modal
		:id="id"
		:is-open="isModalOpen(id)"
		class="dark:text-white"
		:request-loading="batchActionLoading || loading"
		hide-close
		max-width="max-w-2xl"
		@on-confirm="handleConfirm"
		@on-cancel="closeModal"
		@on-close="closeModal"
	>
		<template #title>
			<h4 class="w-full pb-4 text-2xl text-center">
				Email Account
			</h4>
		</template>

		<template #body>
			<AccountActionRadios
				v-model="selectedAction"
				:action-options="options"
			/>
		</template>
	</Modal>
</template>

<script setup lang="ts">
import { ACTION_OPTION_IDS } from '@/constants/accounts/table'
import { useAccount } from '@/composables/useAccount'
import { useAccounts } from '@/composables/useAccounts'
import type { BatchActionOption } from '@/components/table/types'
import { useModal } from '@/composables/useModal'
import { useNotes } from '@/composables/useNotes'

const { accountData, handleSendActivationReminder, loading } = useAccount()
const { performBatchAction, batchActionLoading } = useAccounts()
const { closeModal, isModalOpen } = useModal()
const { getNotes } = useNotes()

const props = defineProps({
	id: {
		type: String,
		required: true
	},
	options: {
		type: Array as PropType<BatchActionOption[]>,
		required: true
	}
})

const { options } = toRefs(props)
const selectedAction = ref<string | null>(null)

const handleConfirm = async () => {
	if (selectedAction.value && accountData.value) {
		const batchAction = options.value?.find(option => option.id === selectedAction.value)
		if (selectedAction.value === ACTION_OPTION_IDS.ACTIVATION) {
			await handleSendActivationReminder(accountData.value?.accountId?.toString())
		} else {
			await performBatchAction({ actionOption: batchAction, selectedIds: [ accountData.value?.accountId?.toString() ], showSuccessToast: true })
			await getNotes(accountData.value?.accountId?.toString(), true)
		}
		closeModal()
	}
}
</script>
