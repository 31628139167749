<template>
	<Modal
		:is-open="openModal"
		:confirm-disabled="confirmDisabled"
		@on-confirm="handleCreateTicket"
		@on-close="closeModal"
	>
		<template #body>
			<form class="py-4 space-y-6 dark:text-mx-gray-400">
				<div class="space-y-4">
					<section>
						<h3 class="text-lg font-semibold dark:text-mx-gray-300">
							<!-- TODO i18n -->
							Assignee
						</h3>
						<div class="flex flex-col gap-4 sm:flex-row">
							<div class="flex-1">
								<label
									for="accountserviceRep"
									class="block text-sm font-medium text-mx-green-700 dark:text-mx-gray-400"
								>
									<!-- TODO i18n -->
									Account Service Representative
								</label>
								<select
									id="accountserviceRep"
									v-model="accountServiceRepSelect"
									class="block w-full py-2 pl-3 mt-1 text-base border rounded-md border-mx-gray-300 dark:text-mx-gray-400 dark:bg-mx-green-800 dark:border-mx-green-700 focus:outline-none focus:ring-mx-orange focus:border-mx-orange sm:text-sm"
								>
									<option
										value=""
										selected
									>
										<!-- TODO i18n -->
										Unassigned
									</option>
									<option
										v-for="option in accountServiceRepOptions"
										:key="option.id"
										:value="option.id"
									>
										{{ option.name }}
									</option>
								</select>
							</div>
							<div class="flex-1">
								<label
									for="account"
									class="block text-sm font-medium text-mx-green-700 dark:text-mx-gray-400"
								>
									<!-- TODO i18n -->
									Account
								</label>
								<input
									id="account"
									:value="readonlyAccountName"
									readonly
									tabindex="-1"
									class="block w-full py-2 pl-3 mt-1 text-base bg-transparent border-none rounded-md border-mx-gray-300 text-mx-gray-400 dark:bg-transparent dark:border-mx-green-700 focus:outline-none focus:ring-transparent focus:border-transparent sm:text-sm"
									@click.prevent="$event.preventDefault()"
								>
							</div>
						</div>
					</section>

					<section>
						<h3 class="text-lg font-semibold dark:text-mx-gray-300">
							<!-- TODO i18n -->
							Ticket Details
						</h3>
						<div class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
							<div class="pt-0 sm:col-span-2">
								<label
									for="subject"
									class="block text-sm font-medium text-mx-green-700 after:content-['*'] after:font-bold after:text-red-500 dark:text-mx-gray-400"
								>
									<!-- TODO i18n -->
									Subject
								</label>
								<input
									id="subject"
									v-model="formData.title"
									type="text"
									name="subject"
									class="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm border-mx-gray-300 dark:text-mx-gray-400 dark:bg-mx-green-800 dark:border-mx-green-700 sm:text-sm focus:outline-none focus:ring-mx-orange focus:border-mx-orange"
									required
								>
							</div>
							<div class="sm:col-span-2">
								<!-- TODO i18n -->
								<FormTextArea
									id="content"
									v-model="ticketContent"
									label="Content"
									label-classes="text-sm font-medium text-mx-green-700 dark:text-mx-gray-400"
									markdown
									required
									@update:compiled-html="(value: string) => (contentHtml = value)"
								/>
							</div>
							<div>
								<label
									for="category"
									class="block text-sm font-medium text-mx-green-700 after:content-['*'] after:font-bold after:text-red-500 dark:text-mx-gray-400"
								>
									<!-- TODO i18n -->
									Category
								</label>
								<select
									id="category"
									v-model="formData.category"
									name="category"
									class="block w-full py-2 pl-3 mt-1 text-base border rounded-md border-mx-gray-300 dark:text-mx-gray-400 dark:bg-mx-green-800 dark:border-mx-green-700 focus:outline-none focus:ring-mx-orange focus:border-mx-orange sm:text-sm"
									required
								>
									<!-- TODO i18n -->
									<option
										v-for="(option, key) in TICKET_CATEGORY_OPTIONS"
										:key="key"
										:value="option"
									>
										{{ option }}
									</option>
								</select>
							</div>
							<div>
								<label
									for="priority"
									class="block text-sm font-medium after:content-['*'] after:font-bold after:text-red-500 text-mx-green-700 dark:text-mx-gray-400"
								>
									<!-- TODO i18n -->
									Priority
								</label>
								<select
									id="priority"
									v-model="formData.priority"
									required
									name="priority"
									class="block w-full py-2 pl-3 mt-1 text-base border rounded-md border-mx-gray-300 dark:text-mx-gray-400 dark:bg-mx-green-800 dark:border-mx-green-700 focus:outline-none focus:ring-mx-orange focus:border-mx-orange sm:text-sm"
								>
									<!-- TODO i18n -->
									<option
										v-for="(option, key) in TICKET_PRIORITY_OPTIONS"
										:key="key"
										:value="option"
									>
										{{ option }}
									</option>
								</select>
							</div>
							<div>
								<label
									for="ticket-tier"
									class="block text-sm font-medium after:content-['*'] after:font-bold after:text-red-500 text-mx-green-700 dark:text-mx-gray-400"
								>
									<!-- TODO i18n -->
									Tier
								</label>
								<select
									id="ticket-tier"
									v-model="formData.tier"
									class="w-full px-3 py-2 capitalize border rounded-lg border-mx-gray-300 dark:border-mx-green-700 dark:bg-mx-green-800"
								>
									<!-- TODO i18n -->
									<option
										v-for="(option, key) in TICKET_TIER_OPTIONS"
										:key="key"
										:value="option"
									>
										{{ option }}
									</option>
								</select>
							</div>
							<div>
								<label
									for="dueDate"
									class="block text-sm after:content-['*'] after:font-bold after:text-red-500 font-medium text-mx-green-700 dark:text-mx-gray-400"
								>
									<!-- TODO i18n -->
									Due Date
								</label>
								<input
									id="dueDate"
									v-model="dueDateRaw"
									required
									type="date"
									name="dueDate"
									class="block w-full px-3 py-2 mt-1 text-base border rounded-md border-mx-gray-300 dark:text-mx-gray-400 dark:bg-mx-green-800 dark:border-mx-green-700 focus:outline-none focus:ring-mx-orange focus:border-mx-orange sm:text-sm"
								>
							</div>
						</div>
					</section>
				</div>
			</form>
		</template>
	</Modal>
</template>

<script setup lang="ts">
import { usePermissions } from '@/composables/usePermissions'
import { useAuth } from '@/composables/useAuth'
import { useTicketing } from '@/composables/useTicketing'
import { formatIsoStringWithTimeZoneOffset } from '@/utils/formatDate'
import { TICKET_CATEGORY_OPTIONS, TICKET_PRIORITY_OPTIONS, TICKET_STATUS_OPTIONS, TICKET_TIER_OPTIONS } from '@/constants/ticketing'
import type { TicketCreatePayload, Author } from '@/types/ticketing'

const props = defineProps({
	openModal: {
		type: Boolean,
		default: false
	},
	accountId: {
		type: String,
		default: ''
	},
	accountName: {
		type: String,
		default: ''
	}
})

const emit = defineEmits([ 'onClose' ])

const { openModal, accountId, accountName } = toRefs(props)
const { firstName, lastName, contactId } = useAuth()
const { fetchPermissions, accountServiceRepOptions } = usePermissions()
const { createTicket } = useTicketing()

onMounted(() => {
	fetchPermissions('notes.create')
})

const emptyAuthor: Author = {
	firstName: '',
	lastName: '',
	email: '',
	id: null
}

const formData = reactive<TicketCreatePayload>({
	author: { ...emptyAuthor },
	priority: TICKET_PRIORITY_OPTIONS.MEDIUM,
	status: TICKET_STATUS_OPTIONS.OPEN,
	category: TICKET_CATEGORY_OPTIONS.GENERAL,
	title: '',
	content: '',
	assignee: { ...emptyAuthor },
	accountId: accountId.value,
	dueDate: '',
	tier: TICKET_TIER_OPTIONS.TIER1
})

// Whenever the modal is opened, reset the form (prevents race condition when clearing on close)
watch(openModal, (val) => {
	if (val) {
		clearTicket()
	}
})

const dueDateRaw = ref('')
watch(dueDateRaw, (newVal) => {
	formData.dueDate = newVal ? formatIsoStringWithTimeZoneOffset(newVal) : ''
})

const accountServiceRepSelect = ref<number | string>(parseInt(contactId.value))
watch(accountServiceRepSelect, (val) => {
	const parsedId = typeof val === 'string' ? parseInt(val) : val
	const found = accountServiceRepOptions.value?.find(rep => parseInt(rep.id) === parsedId)
	formData.assignee.id = parsedId
	formData.assignee.firstName = found?.first || ''
	formData.assignee.lastName = found?.last || ''
	formData.assignee.email = found?.email || ''
})

const readonlyAccountName = computed(() => {
	return accountName.value || ''
})

const ticketContent = ref('')
const contentHtml = ref('')

const setTicketContent = () => {
	formData.content = contentHtml.value?.length ? contentHtml.value : ticketContent.value
}
// update form with the ticket content or the markdown html
watch([ ticketContent, contentHtml ], () => {
	setTicketContent()
})

const confirmDisabled = computed(() => {
	return !formData.title || !formData.content || !formData.dueDate
})

const handleCreateTicket = async () => {
	// Make sure the author is up to date (if needed)
	formData.author.id = parseInt(contactId.value)
	formData.author.firstName = firstName.value || ''
	formData.author.lastName = lastName.value || ''

	await createTicket(formData).then(() => {
		closeModal()
	})
}

const clearTicket = () => {
	formData.author = { ...emptyAuthor }
	formData.assignee = { ...emptyAuthor }
	formData.priority = TICKET_PRIORITY_OPTIONS.MEDIUM
	formData.status = TICKET_STATUS_OPTIONS.OPEN
	formData.category = TICKET_CATEGORY_OPTIONS.GENERAL
	formData.title = ''
	formData.content = ''
	formData.accountId = accountId.value
	formData.dueDate = ''
	formData.tier = TICKET_TIER_OPTIONS.TIER1

	dueDateRaw.value = ''

	accountServiceRepSelect.value = parseInt(contactId.value)
}

const closeModal = () => {
	emit('onClose')
}
</script>
