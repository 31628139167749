import {
	API_BASE,
	API_ENDPOINT_PORTAL_CREATE_LOGIN
} from '@/constants/index.js'
import { useErrorReporter } from '@/composables/useErrorReporter'
import { useToast } from '@/composables/useToast'
import { ref } from 'vue'

export function useCreateLogin () {
	const { bugsnagReport } = useErrorReporter()
	const { addToast } = useToast()
	const loading = ref(false)
	const createLogin = async (data: { accountId: string, contactId: string }) => {
		const { accountId, contactId } = data
		try {
			loading.value = true
			await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_CREATE_LOGIN.replace('{account-id}', accountId).replace('{contact-id}', contactId)}`, {
				method: 'PUT',
				credentials: 'include',
				onResponse ({ response }) {
					if (!response.ok) {
						throw new Error(response._data?.errors[0]?.title)
					} else {
						addToast({
							title: 'Login Created',
							message: 'The login has been created successfully.',
							notificationType: 'success'
						})
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('createLogin - catch: ' + (e as Error).toString()),
				showToast: true,
				toast: {
					title: 'Error creating login',
					message: e instanceof Error ? e.message : 'An error occurred while creating login'
				}
			})
		} finally {
			loading.value = false
		}
	}
	return {
		createLogin,
		loading
	}
}
