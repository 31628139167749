<template>
	<div>
		<FormButton
			button-text="Create Login"
			button-width="w-32"
			@click="openModal(CREATE_LOGIN_MODAL_ID)"
		/>
		<ModalCreateLogin
			id="create-login-modal"
			:open-modal="isModalOpen(CREATE_LOGIN_MODAL_ID)"
			max-width="max-w-3xl"
			title="Create Login"
			:account-id="accountId"
			@on-close="closeModal"
			@on-cancel="closeModal"
		/>
	</div>
</template>

<script lang="ts" setup>
import { useModal } from '@/composables/useModal'
const { openModal, closeModal, isModalOpen } = useModal()
const CREATE_LOGIN_MODAL_ID = computed(() => `create-login-modal-${Date.now()}`)

defineProps({
	accountId: {
		type: String,
		required: true
	}
})
</script>
