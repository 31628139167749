<template>
	<div>
		<!-- TODO i18n -->
		<FormButton
			button-text="Create Ticket"
			button-width="w-32"
			@click="openModal(CREATE_TICKET_MODAL_ID)"
		/>
		<!-- TODO i18n -->
		<ModalCreateTicket
			id="create-ticket-modal"
			:open-modal="isModalOpen(CREATE_TICKET_MODAL_ID)"
			max-width="max-w-3xl"
			title="Create Ticket"
			:account-id="accountId"
			:account-name="accountName"
			@on-close="closeModal"
			@on-cancel="closeModal"
		/>
	</div>
</template>

<script lang="ts" setup>
import { useModal } from '@/composables/useModal'
const { openModal, closeModal, isModalOpen } = useModal()
const CREATE_TICKET_MODAL_ID = computed(() => `create-ticket-modal-${Date.now()}`)

defineProps({
	accountId: {
		type: String,
		required: true
	},
	accountName: {
		type: String,
		required: true
	}
})
</script>
