<template>
	<Modal
		:id="id"
		:is-open="openModal"
		class="dark:text-white"
		max-width="max-w-lg"
		@on-confirm="handleCreateLogin"
	>
		<template #title>
			<h4 class="w-full mt-4 text-2xl text-center">
				Confirm Login Creation
			</h4>
		</template>

		<template #body>
			<div class="flex items-center justify-center w-full px-2 py-4 mx-auto text-center">
				<span class="pl-2 mt-1 text-mx-gray-500 dark:text-mx-gray-50">
					This will send the customer an email to set a password for the new login.
				</span>
			</div>
		</template>
	</Modal>
</template>

<script setup lang="ts">
import { useCreateLogin } from '@/composables/useCreateLogin'
import { useAccount } from '@/composables/useAccount'

const props = defineProps({
	id: {
		type: String,
		required: true
	},
	openModal: {
		type: Boolean,
		default: false
	},
	accountId: {
		type: String,
		required: true
	}
})

const { accountId } = toRefs(props)

const { createLogin } = useCreateLogin()
const { accountData, refetchAccount } = useAccount()

const { closeModal } = useModal()

const handleCreateLogin = async () => {
	await createLogin({ accountId: accountId.value, contactId: accountData.value?.contactId?.toString() || '' })
	await refetchAccount()
	closeModal()
}
</script>
